<template>
  <div class="formBox">
    <el-tabs v-model="activeName" >
      <el-tab-pane label="潜在线索详情" name="first">
        <div style="display: flex; align-items: center">
          <h4 class="formBox_header">基本信息</h4>
        </div>
        <el-form :rules="updatePwdRules" ref="form" :model="form" label-width="120px">
          <el-form-item label="潜在线索名称" prop="clueStage">
            <el-radio-group v-model="form.clueStage">
              <el-radio :label="item.codeName" v-for="item in stageList" :key="item.id">
                {{ item.codeName }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所在地区" prop="clueName">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="潜在线索类型" prop="clueName">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="供应商要求" prop="clueName">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="潜在线索描述">
            <div class="txtree">
              <el-input
                v-model="form.clueOneDescription"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入需求介绍"
                maxlength="100"
                show-word-limit
              />
            </div>
          </el-form-item>

          <h4 class="h4_share">客户信息</h4>
          <el-form-item label="客户类型">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="对接人姓名">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
          <el-form-item label="对接人职位">
            <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
          </el-form-item>
        </el-form>

        <div class="button_box">
           <div class="cancel button" @click="clickCancel">返回</div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      activeName: 'first',

      form: {}
    }
  },

  created() {},

  mounted() {},
  //方法集合
  methods: {
    clickCancel(){
        this.$router.back()
    }
  }
}
</script>
  <style scoped lang="less">
  ::v-deep .el-tabs__item {
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    color: #448aff;
    position: relative;
}
.formBox {
  padding: 20px;
  background: #fff;
  .formBox_header {
    margin: 10px 20px 20px 30px;
    padding-left: 5px;
    border-left: 4px solid #4e93fb;
  }
  .img_tips {
    font-size: 12px;
    color: #6ba1ff;
  }
  .h4_share {
    margin: 50px 50px 30px 30px;
    padding-left: 5px;
    border-left: 4px solid #4e93fb;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
  .tiket_flex {
    display: flex;
  }
  .ticket_Type {
    width: 600px;
    // height: 980px;
    padding: 20px 0px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    padding-left: 20px;
    min-height: 100px;
    .add_information {
      margin-left: 40px;
      width: 433.54px;
      min-height: 100px;
      border: 1px dashed #c4c4c4;
      border-radius: 2px;
      margin-bottom: 15px;
      .online_conference {
        .online_color {
          color: #999999;
        }
      }
      .online_conferences {
        margin-top: 16px;
        width: 447.76px;
        height: 305.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        margin-bottom: 10px;
        .online_color {
          color: #999999;
        }
      }
    }
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      padding: 7px 0px;
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;

    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .cover {
    display: flex;
  }

  .picker {
    width: 240px !important;
  }

  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }

  .editInformation {
    margin-top: 40px;
    margin-left: 58px;
    color: #999999;
    font-size: 14px;

    .item {
      margin-bottom: 6px;
    }
  }
  .button_box {
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    padding-bottom: 55px;
    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 9;
    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 598.31px;
      height: 437px;
    }
  }
}
.inline_form_box {
  display: flex;
  // align-items: center;
  ::v-deep .el-input {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
    width: 217px;
  }
}
.informationType {
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 220px;
  }
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;

  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .original .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
}
::v-deep .el-upload-dragger {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.editorbar_box {
  width: 50%;
  min-width: 600px;
  min-height: 400px;
  .editBar {
    width: 100%;
    // height: 487px;
  }
}
.el-upload__text {
  color: #ccc;
}
::v-deep .upload .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-icon-circle-plus {
  font-size: 30px;
  margin-left: 10px;
}
::v-deep .name_form .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
.file_flex {
  margin-bottom: 10px;
  // width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.cover {
  display: flex;
  flex-direction: column;
}
</style>
  